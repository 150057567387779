<template>
    <div class='demo'>
      <div style="display: flex;">
          <Headbuttom></Headbuttom>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input></div>
      </div>

      <div class="attheadfrom" v-show="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
       
        <el-form-item label="设置安装位置">
          <el-input
            v-model="searchParams.serial_number"
          ></el-input>
        </el-form-item>
        <el-form-item label="推送时间">
          <el-date-picker
            v-model="searchParams.times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input
            v-model="searchParams.name"
            
          ></el-input>
        </el-form-item>

        <el-form-item label="部门">
         <Select @handleCheckChange="handleCheckChange" ></Select>
        </el-form-item>
       
        
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
      </div>

      <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
      :configs="configs"></Tables>

    </div>
</template>

<script>
import Select from '@/components/selectree/wltreeselect'
import Tables from '@/components/table'
import {formatDate} from '../../utils/date'
export default {
    components:{
        Tables,
        Select
    },
    data(){
        return{
           flag:false,
           searchParams:{
             name:'',
             serial_number:'',
             times:'',
             
             dept_id:'',
             type:'-1'
           },
           UserInfo:JSON.parse(window.localStorage.getItem('user')),
          tableLabel:[
            {
              prop: "send_time",
              label: '推送时间',
              align: "center",
            },
            {
              prop: "user_name",
              label: '姓名',
              align: "center",
            },
            {
              prop: "dept_name",
              label: '部门',
              align: "center",
            },
            {
              prop: "send_content",
              label: '推送内容',
              align: "center",
            },
            {
              prop: "state",
              label: '是否成功',
              align: "center",
            },
            // {
            //   prop: "user_name",
            //   label: '经办人',
            //   align: "center",
            // },
            // {
            //   prop: "remark",
            //   label: '备注',
            //   align: "center",
            // },  
          ],
          tableData:[],
          // 分页数据
          configs: {
            page_index:1,
            pagesize: 10,
            total: 200,
            loading: false, // 加载状态
          },
          flags: {
            show: false, // 多选是否显示
            ishow: true, // 序号是否显示
          },
        }
    },

    mounted(){
      this.GetreDprodata()
    },
    methods:{
      handFromshow () {
        this.flag = !this.flag
      },
      onSubmit(){
     
        this.GetreDprodata()
      },
      // 获取企业部门id
      handleCheckChange(val){
          let dept_id=[]
          val.forEach(el=>{
              dept_id.push(el.id)
          })
          this.searchParams.dept_id = dept_id.join(",");
          console.log(`677`,this.searchParams.dept_id)
      },
      // 列表数据
      GetreDprodata(){

        let data={
          method:'co.ipc.message.list',
          page_index:this.configs.page_index,
          page_size:this.configs.page_size,
          agent_id:this.UserInfo.agent_id,
          name:this.searchParams.name,
          dept_id:this.searchParams.dept_id,
          type:this.searchParams.type,
          serial_number:this.searchParams.serial_number,
          start_time:new Date(this.searchParams.times[0]).getTime()/1000,
          end_time:new Date(this.searchParams.times[1]).getTime()/1000,
          
        }
        console.log(data)
        this.$serve(data).then(res=>{
          if(res.data.code==0){
            this.tableData = res.data.data
            this.configs.total = res.data.count
            this.tableData.forEach(el=>{
               el.send_time = formatDate(new Date(el.send_time * 1000), 'yyyy-MM-dd hh:mm')
               
               el.state = el.state=='0' ? el.state ='短信失败' :el.state =='1' ? el.state = '语音失败' : el.state = '成功'
            })
            
          }
        })
      },
      handlerow(){

      },
      handlpages(v){
        this.configs.page_index=v
        thi.GetreDprodata()

      },
      handleSizeChange(){

      }
    }
}
</script>
<style scoped>
.demo{
    background-color: #fff;
    padding: 20px;

}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
</style>